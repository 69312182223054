<template>
  <oracle-erp-business />
</template>

<script>
import OracleErpBusiness from "../../components/solutions/erp/OracleErpBusiness.vue";
export default {
  components: { OracleErpBusiness },
};
</script>

<style></style>
